/*
	Atmosphere by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

(function($) {

	var	$window = $(window),
		$body = $('body'),
		$header = $('#header'),
		$banner = $('#banner');

	// Breakpoints.
		breakpoints({
			xlarge:   [ '1281px',  '1680px' ],
			large:    [ '981px',   '1280px' ],
			medium:   [ '737px',   '980px'  ],
			small:    [ '481px',   '736px'  ],
			xsmall:   [ '361px',   '480px'  ],
			xxsmall:  [ null,      '360px'  ]
		});

	// Play initial animations on page load.
		$window.on('load', function() {
			window.setTimeout(function() {
				$body.removeClass('is-preload');
			}, 100);
		});

	// Scrolly.
		$('.scrolly').scrolly({
			offset: function() {
				return $header.height();
			}
		});

	// Header.
		if ($banner.length > 0 && $header.hasClass('alt')) {

			$window.on('resize', function() { $window.trigger('scroll'); });

			$banner.scrollex({
				bottom:		$header.outerHeight(),
				terminate:	function() { $header.removeClass('alt'); },
				enter:		function() { $header.addClass('alt'); },
				leave:		function() { $header.removeClass('alt'); }
			});

		}

	// Menu.
		var $menu = $('#menu');

		$menu._locked = false;

		$menu._lock = function() {

			if ($menu._locked)
				return false;

			$menu._locked = true;

			window.setTimeout(function() {
				$menu._locked = false;
			}, 350);

			return true;

		};

		$menu._show = function() {

			if ($menu._lock())
				$body.addClass('is-menu-visible');

		};

		$menu._hide = function() {

			if ($menu._lock())
				$body.removeClass('is-menu-visible');

		};

		$menu._toggle = function() {

			if ($menu._lock())
				$body.toggleClass('is-menu-visible');

		};

		$menu
			.appendTo($body)
			.on('click', function(event) {

				event.stopPropagation();

				// Hide.
					$menu._hide();

			})
			.find('.inner')
				.on('click', '.close', function(event) {

					event.preventDefault();
					event.stopPropagation();
					event.stopImmediatePropagation();

					// Hide.
						$menu._hide();

				})
				.on('click', function(event) {
					event.stopPropagation();
				})
				.on('click', 'a', function(event) {

					var href = $(this).attr('href');

					event.preventDefault();
					event.stopPropagation();

					// Hide.
						$menu._hide();

					// Redirect.
						window.setTimeout(function() {
							window.location.href = href;
						}, 350);

				});

		$body
			.on('click', 'a[href="#menu"]', function(event) {

				event.stopPropagation();
				event.preventDefault();

				// Toggle.
					$menu._toggle();

			})
			.on('keydown', function(event) {

				// Hide on escape.
					if (event.keyCode == 27)
						$menu._hide();

			});


})(jQuery);

window.formEnable = function () {
   var form = $('#contact-form');

   var validate = function (required) {
      required.each(function (index, input) {
         var pattern = $(input).attr('pattern') ? new RegExp($(input).attr('pattern')) : {test: function () {return true;}};

         if ($(input).val().trim() === '' || !pattern.test($(input).val())) {
            $(input).attr('invalid','');
         } else {
            $(input).removeAttr('invalid');
         }
      });
   };

    var doSubmit = function (button, status) {
      button.css('cursor', 'progress');

      return $.post({
         url: form.attr('action'),
         data: form.serialize(),
         timeout: 3000, // 3 sec
         headers: {
            'Accept': 'application/json',
         },
      })
      .always(function () {
         button.hide();
         button.css('cursor', 'pointer');
         status.css('color', '');
      })
      .then(
/* jshint -W100 */
         function () {
            status.text('Благодарим за проявения интерес').show();
            form[0].reset();
         },
         function () {
            status.css('color', 'orange');
            status.text('Възникна проблем, моля опитайте отново').show();
            setTimeout(function () {
               status.hide();
               button.show();
            }, 2500); // 3 sec
         }
/* jshint +W100 */
      );
   };

   form.find('input[type=submit]').click(function () {
      self = $(this);
      validate(form.find('[required]'));

      return (function (invalid) {
         if (invalid.length > 0) {
            $(invalid[0]).focus();
            return false;
         }

         return doSubmit(self, $('#form-status'));
      })(form.find('[invalid]'));
   });
};
